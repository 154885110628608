export const MeasureTextQuestions = {
  data () {
    return {
      value: '',
      skipButton: true,
      disabledButton: false,
      hasValue: false
    }
  },
  created () {
    this.value = this.question.value
  },
  mounted () {
    this.setSkipButton(this.value)
  },
  methods: {
    emitSkipButton () {
      this.hasValue = !!this.value
      this.$emit('modelChange')
    },
    setSkipButton (value) {
      this.skipButton = this.question.optional && !value
      this.setDisabledButton(value, this.skipButton)
      this.emitSkipButton()
    },
    setDisabledButton (value, skip) {
      this.disabledButton = !value && !skip
    },
    emitChanged (autosave) {
      // on change
      this.$emit('changed', this.index, this.question, this.value, autosave)
    },
    emitQuestionAnswered () {
      // keydown
      this.$emit('changed', this.index, this.question, this.value, true)
      if (this.value && !this.skipButton) {
        this.$emit('questionAnswered', this.index, this.question, this.value)
        return
      }
      this.$emit('questionAnswered', this.index, this.question, null)
    },
    emitSaveOnly () {
      if (this.question.value !== this.value) {
        this.$emit('saveOnly', this.value, this.question)
      }
    }
  }
}

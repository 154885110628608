<template>
  <div class="flex-column-center">
    <div>
      <div>
        <span v-html="settings.HIPPA_client_script" />
      </div>
      <button
        type="button"
        class="btn btn-primary"
        @click.prevent="$emit('iUnderstand')"
      >
        {{ $t("iUnderstand") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HippaAgreement',
  computed: {
    ...mapGetters({
      settings: 'generalSettings'
    })
  }
}
</script>
<style lang="scss" scoped>
  .flex-column-center {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    > div {
      max-width: 100%;
    }
  }
</style>

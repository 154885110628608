<template>
  <div class="form-group">
    <select
      v-model="value"
      class="form-control"
      @change="emitQuestionAnswered"
    >
      <option
        v-for="(option, i) in question.answers"
        :key="i"
        :value="option.value"
        :disabled="question.readOnly"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
export default {
  name: 'QuestionSelect',
  mixins: [Helpers],
  props: ['question', 'index'],
  data () {
    return {
      value: 0,
      hasValue: false
    }
  },
  computed: {
    isMobile () {
      return this.$isMobile()
    }
  },
  created () {
    this.value = this.question.value
  },
  mounted () {
    this.emitSkipButton(this.value)
  },
  methods: {
    emitSkipButton () {
      this.hasValue = !!this.value
      this.$emit('modelChange')
    },
    emitQuestionAnswered () {
      this.emitSkipButton()
      this.$emit('changed', this.index, this.question, this.value, true)
      this.$emit('questionAnswered', this.index, this.question, this.value)
    }
  }
}
</script>

<template>
  <div class="form-group">
    <input
      :id="index"
      v-model="value"
      autocomplete="off"
      type="text"
      class="form-control"
      :readonly="question.readOnly"
      :disabled="question.readOnly"
      :name="index"
      @keydown.enter.prevent="emitQuestionAnswered"
      @change="emitChanged(true)"
      @blur="emitSaveOnly"
      @keyup="evt => setSkipButton(evt.target.value)"
    >
    <button
      v-if="showDone"
      type="button"
      :disabled="disabledButton"
      class="btn btn-link float-right mt-3 done"
      @click.prevent="emitQuestionAnswered"
    >
      {{ skipButton ? $t("skip") : $t("done") }}
    </button>
  </div>
</template>

<script>
import { MeasureTextQuestions } from '@/mixins/MeasureTextQuestions'

export default {
  name: 'QuestionInput',
  mixins: [MeasureTextQuestions],
  props: ['question', 'index', 'showDone']
}
</script>

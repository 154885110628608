<template>
  <hippa-agreement
    v-if="!hippaAgreement && !customMeasureId"
    @iUnderstand="onIUnderstand"
  />
  <div
    v-else
    class="single-measure"
    :class="{'measure-catmh':singleMeasure && singleMeasure.isCatMh}"
  >
    <div
      v-if="showHeader"
      id="form-header"
      class="form-header"
    >
      <router-link
        v-if="showHeaderBackPatient"
        class="back"
        :to="{ name: 'SingleMeasure', params: { measureId: measureId, pageId: pageId - 1} }"
      >
        {{ $t("back") }}
      </router-link>
      <a
        v-if="showHeaderBackClinician"
        href=""
        class="back"
        @click.prevent="goPrevPage"
      >
        {{ $t("back") }}
      </a>
      <a
        v-if="showHeaderExit"
        href=""
        class="exit"
        @click.prevent="promptBeforeClose"
      >
        {{ $t("exit") }}
      </a>
    </div>
    <form
      v-if="singleMeasure && singleMeasure.page && questionsLoaded"
      class="single-measure-form"
    >
      <div
        v-if="singleMeasure.page.title"
        class="outer-flex-container"
      >
        <div class="question-container">
          <span class="title">
            <p v-html="singleMeasure.page.title" />
          </span>
        </div>
      </div>
      <div
        v-for="(question, index) in filteredQuestions"
        :id="`item-${index}`"
        :key="`${singleMeasure.clientMeasureId}-${singleMeasure.page.id}-${question.id}`"
      >
        <single-measure-question
          :question="question"
          :index="index"
          :questions-length="filteredQuestions.length"
          :clinician-editing="clinicianEditing"
          @skipDoneButtonPressed="setSkipDoneButtonPressed"
          @saveOnly="saveOnly"
          @rememberPrompt="rememberPrompt"
          @goNext="goNext"
          @next="next"
          @finish="finishPage"
        />
      </div>
    </form>
    <single-measure-finish
      v-if="singleMeasure && singleMeasure.page && questionsLoaded"
      :class="{'clinician-editing': clinicianEditing}"
      :total-unanswered="totalUnanswered"
      :questions-length="filteredQuestions.length"
      :has-more-pages="singleMeasure.nextPage"
      :custom-measure-id="customMeasureId && !clinicianEditing"
      :clinician-editing="clinicianEditing"
      :is-cat-mh="singleMeasure.isCatMh"
      :is-scorable="singleMeasure.is_scorable"
      :page-number="singleMeasure.page.id"
      :audition="audition"
      :show-unanswered-question-count="showUnansweredQuestionCount"
      @submit="submit"
      @goBack="goBack"
    />
  </div>
</template>
<script>
import HippaAgreement from '@/components/measures/HippaAgreement'
import SingleMeasureFinish from '@/components/measures/SingleMeasureFinish'
import SingleMeasureQuestion from '@/components/measures/SingleMeasureQuestion'
import { ExecuteExpression } from '@/mixins/ExecuteExpression'
import { Helpers } from '@/mixins/Helpers'
import { MeasureBranching } from '@/mixins/MeasureBranching'
import { MeasureStepNavigation } from '@/mixins/MeasureStepNavigation'
import { loggedUserIsRespondent } from '@/mixins/UserManagement/UserRoles'
import { UPDATE_PAGE_QUESTION_VALUE } from '@/store/modules/measures/constants'
import { mapGetters, mapMutations } from 'vuex'

const QUESTION_TYPE_TEXT = 'text'
const QUESTION_TYPE_TITLE = 'title'
const QUESTION_TYPE_PROMPT = 'prompt'
const SAVE_AUDITION_PAGE = 'SAVE_AUDITION_PAGE'
const SAVE_MEASURE_PAGE = 'SAVE_MEASURE_PAGE'

const initialData = () => {
  return {
    completed: false,
    step: 0,
    instantScroll: false,
    finish: false,
    questionsLoaded: false,
    savingQuestion: null,
    reachedLastStep: false,
    lastStepPosition: null,
    currentStep: 0,
    animationTime: 500,
    divHeight: window.innerHeight,
    measure: {},
    questions: [],
    alertsShown: [],
    questionFormulaLastResult: [],
    questionFormulaCurrentResult: [],
    questionModalShowed: {},
    fromGoBack: false,
    submittedWhileSavingQuestion: false,
    loadingObject: null,
    skipDoneButtonPressed: false
  }
}

const scrollToStep = (step, component, questionContainerIndex) => {
  component.$nextTick(() => {
    const config = getConfig(component)
    const checkExist = setInterval(function () {
      if (component.questionsLoaded) {
        clearInterval(checkExist)
        config.container = component.audition ? '.body-only-header-layout' : component.customMeasureId ? component.clinicianEditing ? '.clinician-edit-measure-responses .overlay-content' : '.overlay-content' : config.container
        scroll(step, component, questionContainerIndex, config)
      }
    }, 500)
  })
}

const getConfig = (component) => {
  const config = { easing: 'ease-in', offset: -108, x: false, y: true, cancelable: false }

  // not ios and not safari web
  if (!component.isIos && !(component.currentBrowser.isSafari && navigator.userAgent.indexOf('Mobile') === -1)) {
    config.container = '.single-measure'
    config.offset = component.audition ? -135 : component.clinicianEditing ? -19 : -46
  }
  return config
}
const scroll = (step, component, questionContainerIndex, config) => {
  component.$scrollTo(step === 0 ? '.form-header' : `#item-${step}`, component.animationTime, config)
  if (questionContainerIndex) {
    const elementToFocus = document.querySelector(`#item-${questionContainerIndex} input, #item-${questionContainerIndex} textarea, #item-${questionContainerIndex} button`)
    // If we have focus element and if its not mobile trigger the autofocus
    if (elementToFocus && navigator.userAgent.indexOf('Mobile') === -1) {
      elementToFocus.focus()
    }
  }
}
let loadingObject = null
export default {
  name: 'SingleMeasure',
  components: {
    SingleMeasureFinish,
    SingleMeasureQuestion,
    HippaAgreement
  },
  mixins: [ExecuteExpression, Helpers, MeasureBranching, MeasureStepNavigation],
  props: ['customMeasureId', 'clinicianEditing', 'currentPage', 'audition'],
  data () {
    return Object.assign({}, initialData(), { pageId: 0 })
  },
  computed: {
    showHeader () {
      const hasSomethingToShow = this.showHeaderExit || this.showHeaderBackClinician || this.showHeaderBackPatient
      return (!this.customMeasureId || (this.customMeasureId && this.clinicianEditing)) && hasSomethingToShow
    },
    showHeaderExit () {
      return !this.clinicianEditing
    },
    showHeaderBack () {
      return !this.finish && this.pageId > 0
    },
    showHeaderBackClinician () {
      return this.showHeaderBack && this.clinicianEditing
    },
    showHeaderBackPatient () {
      return this.showHeaderBack && !this.clinicianEditing
    },
    showUnansweredQuestionCount () {
      // don't show if all questions are optionals
      return this.filteredQuestions.find((q) => {
        return !q.optional
      })
    },
    getMeasureAction () {
      return this.audition ? 'GET_AUDITION_MEASURE' : 'GET_SINGLE_MEASURE'
    },
    savemeasurePageAction () {
      return this.audition ? SAVE_AUDITION_PAGE : SAVE_MEASURE_PAGE
    },
    saveMeasurePageQuestionAction () {
      return this.audition ? 'SAVE_MEASURE_QUESTION_AUDITION' : 'SAVE_MEASURE_QUESTION'
    },
    currentBrowser () {
      return this.$currentBrowser()
    },
    isIos () {
      return this.$isIos()
    },
    hippaAgreement () {
      return this.$store.getters.hippaAgreement
    },
    filteredQuestions () {
      return this.getQuestionsToShow()
    },
    totalUnanswered () {
      return this.getUnanswered(this.filteredQuestions)
    },

    lastUnansweredId () {
      const id = this.filteredQuestions.findIndex((q) => {
        return q.id === this.currentStep
      })
      return id >= 0 ? id : 0
    },
    ...mapGetters({
      singleMeasure: 'activeMeasure',
      auditionData: 'getAuditionData'
    })
  },
  watch: {
    '$route.params' (newParams, oldParams) {
      const paramsChanged = !(this.$arraysEqual(newParams, oldParams))
      // validate that params has changed for a specific issue in safari
      if (paramsChanged) {
        this.getMeasureData(newParams, oldParams).then(() => {
          this.handleSingleMeasureUpdate()
        }).catch((e) => {
          this.getMeasureErrorCallback(e)
        })
      }
    },
    'singleMeasure' () {
      if (!this.singleMeasure.inProgress) {
        this.questionsLoaded = true
      }
    }
  },
  beforeDestroy: function () {
    this.destroySubmitLoadingObject()
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
    this.setSafaroNoMobileFix(false)
    const s = initialData()
    Object.keys(s).forEach(key => {
      if (key !== 'questionModalShowed') {
        this[key] = s[key]
      }
    })
  },
  mounted () {
    this.$root.$on('goPrevPage', () => {
      this.goPrevPage()
    })
  },
  created () {
    this.setSafaroNoMobileFix(true)
    this.pageId = this.getCurrentPage()
    this.measureId = this.customMeasureId ? this.customMeasureId : this.$route.params.measureId
    if (!this.singleMeasure || !this.singleMeasure.page || this.singleMeasure.clientMeasureId !== this.measureId || this.singleMeasure.page.id !== this.pageId || this.clinicianEditing) {
      const loader = this.$loading.show()
      this.getMeasureData({ measureId: this.measureId, pageId: this.pageId }, {}).then(() => {
        this.handleSingleMeasureStart()
      }).catch((e) => {
        this.getMeasureErrorCallback(e)
      }).finally(() => {
        loader.hide()
        this.$emit('loaded')
      })
    } else {
      this.questionsLoaded = true
      this.handleSingleMeasureStart()
    }
  },
  methods: {
    ...mapMutations({
      clinicianSaveQuestion: 'measures/CLINICIAN_SAVE_MEASURE_QUESTION',
      mutateQuestionValue: UPDATE_PAGE_QUESTION_VALUE
    }),
    getUnanswered (questions) {
      return questions.reduce((unanswered, q) => {
        if (q.value === '' && !q.optional) {
          unanswered += q.isMultiQuestion ? this.getUnanswered(q.multiQuestion) : 1
        }
        return unanswered
      }, 0)
    },
    getCurrentPage () {
      if (this.audition && this.singleMeasure && this.singleMeasure.page) {
        return this.singleMeasure.page.id || 0
      }
      return this.customMeasureId ? this.currentPage ? this.currentPage : 0 : Number(this.$route.params.pageId)
    },
    handleSingleMeasureStart () {
      if (this.singleMeasure.redirect) {
        return this.redirect()
      }
      this.currentStep = this.singleMeasure.lastSavedQuestion
      if (this.singleMeasure.inProgress) {
        const savedStep = this.pageId === this.singleMeasure.lastSavedPage ? this.lastUnansweredId : undefined
        this.next(savedStep).then(() => {
          this.questionsLoaded = true
        })
      }
    },
    redirect () {
      if (!this.singleMeasure.clientMeasureId) {
        return this.$store.dispatch('FINISH_SESSION', true).then(() => {
          this.$router.push({ name: 'SessionResultSent' })
        })
      }
      return this.$router.push({
        name: 'SingleMeasure',
        params: { measureId: this.singleMeasure.clientMeasureId, pageId: 0 }
      })
    },
    isInformativeType (q) {
      return !q.isMultiQuestion && [QUESTION_TYPE_TEXT, QUESTION_TYPE_TITLE, QUESTION_TYPE_PROMPT].includes(q.type)
    },
    setSafaroNoMobileFix (status) {
      const height = status ? 'auto' : '100%'
      const action = status ? 'add' : 'remove'
      const element = document.querySelector('html')
      if (this.currentBrowser.isSafari && navigator.userAgent.indexOf('Mobile') === -1) {
        element.style.height = height
        element.classList[action]('safari-no-mobile')
      }
    },
    onIUnderstand () {
      const loader = this.$loading.show()
      this.$store.dispatch('SAVE_HIPPA_AGREEMENT', {}, { root: true }).then(response => {
        if (response && response.status !== 200) {
          this.$handleApiError(response)
        }
      }).catch((e) => {
        this.$handleApiError(e)
      }).finally(() => {
        loader.hide()
      })
    },
    showQuestionModal (question) {
      if (!this.canShowQuestionModal(question)) {
        return false
      }
      const options = {
        message: question.text,
        title: `<i class="fa fa-exclamation-circle"></i><div><p>${this.$t('pleaseRead')}</p> </div>`,
        timeout: 0,
        close: false,
        overlay: true,
        toastOnce: true,
        class: 'owl alert',
        id: 'measurePrompt',
        position: 'topCenter',
        icon: null,
        buttons: [
          [`<button class="exit">${this.$t('ok')}</button>`, function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true]
        ]
      }
      this.$toast.show(options)
    },
    canShowQuestionModal (question) {
      const questionIndex = this.singleMeasure.page.id + '-' + question.id
      // The modal was already shown or page is loading.
      if (this.questionsLoaded === false || Object.keys(this.questionModalShowed).length > 0) {
        return false
      }

      // When Clinical User and not in audition mode clinical user answering skipped questions.
      // OWL-7510 & OWL-8025
      if (!loggedUserIsRespondent() && !this.audition) {
        return false
      }

      // Otherwise user is respondent, or clinician is using measure Preview.
      this.questionModalShowed[questionIndex] = true
      return true
    },

    handleSave (params, action) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, params).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    submit (autoSave, complete, stayInCurrentPage) {
      // Add Loader at the beginning of the event.
      this.createSubmitLoadingObject()
      this.doSubmit(autoSave, complete, stayInCurrentPage).finally(() => {
      // when submittedWhileSavingQuestion, the doSubmit process return early, but we still want to show the
      // loading until the submit is re-launched after the save question at the save().
        if (!this.submittedWhileSavingQuestion) {
          this.destroySubmitLoadingObject()
        }
      })
    },
    doSubmit (autoSave, complete, stayInCurrentPage) {
      return new Promise((resolve) => {
        // on Saving Question Context this submit process is skipped.

        if (this.savingQuestion !== null) {
          this.submittedWhileSavingQuestion = true
          return resolve(true)
        }

        // Get parameters
        const parameters = this.getSubmitParams(complete)

        // Do handle Save Execution
        this.handleSave(parameters, this.savemeasurePageAction).then((response) => {
          // Do nothing because we should stay on autosave.
          if (autoSave && stayInCurrentPage) {
            return resolve(true)
          }

          // Handle after save Callback Promise
          this.afterSaveCallback(autoSave, complete, response, parameters).then(result => {
            return resolve(result)
          })
        }).catch((e) => {
          resolve(false)
          this.$handleApiError(e)
          this.$router.push({ name: 'Session' })
        })
      })
    },
    afterSaveCallback (autoSave, complete, response, parameters) {
      return new Promise((resolve) => {
        // Go To next Page if needs to.
        if (this.goingToNextPage(autoSave, response)) {
          this.goToNextPage()
          return resolve(true)
        }

        // at This point we consider that the measure is completed.
        this.completed = complete
        // We emit a different event when clinician editing or Audition.
        if (this.customMeasureId || this.clinicianEditing || this.audition) {
          this.$emit('customizationFinished', parameters)
          return resolve(true)
        }

        // We go to the next Measure when there is another measure in the session.
        if (complete && response.nextMeasureInSession) {
          this.goToNextMeasureInSession(response)
          return resolve(true)
        }

        // Once everything has been completed we move to finalize session.
        if (complete) {
          this.$store.dispatch('FINISH_SESSION', true).then(() => {
            this.$router.push({ name: 'SessionResultSent' })
          })
          return resolve(true)
        }

        // Otherwise just mark component as finished
        this.finish = true
        return resolve(true)
      })
    },
    goToNextMeasureInSession (parameters) {
      this.resetState()
      return this.$router.push({
        name: 'SingleMeasure',
        params: {
          measureId: parameters.nextMeasureInSession,
          pageId: 0
        }
      })
    },
    getSubmitParams (complete) {
      // Defaults
      const parameters = {
        clientMeasureId: this.singleMeasure.clientMeasureId,
        pageId: this.singleMeasure.page.id,
        complete: !!complete,
        questionAnswers: this.gatherQuestionsAnswers(),
        last_answered_question: this.lastUnansweredId
      }

      // Add Skipped Questions
      if (this.singleMeasure.skipQuestions) {
        parameters.missingAnswers = this.totalUnanswered
      }

      // Add Auditioning Measure Params flow.
      if (this.savemeasurePageAction === SAVE_AUDITION_PAGE) {
        parameters.measureId = this.measureId
        parameters.measureData = this.auditionData
      }

      return parameters
    },
    goingToNextPage (autoSave, response) {
      const submittingToNextPage = !autoSave && this.singleMeasure.nextPage
      const autoSavingNextPage = autoSave && response.nextPage && this.totalUnanswered === 0
      return submittingToNextPage || autoSavingNextPage
    },
    goToNextPage () {
      if (this.validateAndDoTherapistPageMove(1)) {
        return true
      }

      if (!this.customMeasureId) {
        this.$router.push({ name: 'SingleMeasure', params: { measureId: this.measureId, pageId: this.pageId + 1 } })
        return true
      }

      return false
    },
    validateAndDoTherapistPageMove (modifier) {
      return this.clinicianEditing || this.audition ? this.doTherapistPageMove(modifier) : false
    },
    doTherapistPageMove (modifier) {
      const oldP = { measureId: this.measureId, pageId: this.pageId }
      const newP = { measureId: this.measureId, pageId: Number(this.pageId) + modifier }
      this.getMeasureData(newP, oldP).then(() => {
        this.handleSingleMeasureUpdate()
      }).catch((e) => {
        this.getMeasureErrorCallback(e)
      })
      return true
    },
    finishPage () {
      this.submit(true, false)
    },
    getLastAnswerToShow () {
      const entries = Object.values(this.filteredQuestions)
      let index = this.filteredQuestions.length
      for (let i = this.filteredQuestions.length - 1; i >= 0; i--) {
        const question = entries[i]
        if (!this.isInformativeType(question)) {
          break
        }
        index = i
      }
      return index
    },
    next (currentStep, question, value) {
      const loader = this.$loading.show()
      return this.save(value, question).then(() => {
        this.goNext(currentStep)
      }).finally(() => {
        if (loader) {
          loader.hide()
        }
      })
    },
    goNext (currentStep) {
      const nextQuestionPosition = this.getNextUnansweredStep(currentStep, this.fromGoBack)
      this.fromGoBack = false
      this.step = nextQuestionPosition.questionToShow
      const isLastQuestion = this.step === this.lastStepPosition
      if (this.filteredQuestions.length === 1) {
        if (isLastQuestion && !this.reachedLastStep) {
          // need to listen for a button event here that says "WE ARE SKIPPING EXPLICITLY"
          if (this.skipDoneButtonPressed) {
            this.reachedLastStep = true
            this.step = 1
          }
        }
        scrollToStep(this.step, this, this.step)
      } else {
        if (isLastQuestion && !this.reachedLastStep) {
          this.reachedLastStep = true
          scrollToStep(this.filteredQuestions.length, this, this.filteredQuestions.length)
        } else if (!isLastQuestion || !this.reachedLastStep) {
          this.reachedLastStep = this.step === this.lastStepPosition
          scrollToStep(this.step, this, nextQuestionPosition.missingQuestionPosition)
        }
      }
    },
    promptBeforeClose () {
      const promptOptions = {
        title: this.$t('areYouSureToExit'),
        message: this.$t('answersSaved'),
        okButton: this.$t('yesExit'),
        cancelButton: this.$t('noResume')
      }
      this.$promptBeforeAction(promptOptions, () => {
        this.$router.push({ name: 'Session' })
      })
    },
    rememberPrompt (prompt) {
      const options = {
        message: prompt,
        theme: 'light',
        position: 'topCenter',
        overlay: true,
        close: false,
        icon: '',
        class: 'singleButton owl',
        timeout: 0,
        zindex: 999,
        buttons: [
          [`<button class="exit text-capitalize">${this.$t('ok')}</button>`, function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }]
        ]
      }
      this.$toast.info(options)
    },
    saveOnly (values, question) {
      return this.save(values, question, true)
    },
    save (values, question) {
      this.savingQuestion = question ? question.id : null
      let params = {}
      return new Promise((resolve) => {
        if (!question || (question.readOnly && !question.isMultiQuestion)) {
          this.savingQuestion = null
          return resolve(false)
        }

        this.emitSkippedAnswered(question)
        this.currentStep = question.id
        params = {
          clientMeasureId: this.singleMeasure.clientMeasureId,
          pageId: this.pageId,
          questionId: question.id,
          value: values,
          measureId: this.measureId
        }
        if (this.audition) {
          params.measureData = this.auditionData
        }

        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', this.clinicianEditing)
        this.handleSave(params, this.saveMeasurePageQuestionAction).then((response) => {
          if (response.data) {
            this.mutateQuestionValue({ questionId: response.data.id, newValue: response.data.value })
            if (this.clinicianEditing || this.audition) {
              this.clinicianSaveQuestion(params)
            }
            if (this.audition) {
              this.savingQuestion = null
              this.doSubmit(true, false, true).then(() => {
                return resolve(true)
              })
              return true
            }
          }
          resolve(true)
        }).catch((error) => {
          this.$handleApiError(error)
        }).finally(() => {
          this.savingQuestion = null
          if (this.submittedWhileSavingQuestion) {
            this.submittedWhileSavingQuestion = false
            this.submit(false, !this.singleMeasure.nextPage, false)
          }
        })
      })
    },
    emitSkippedAnswered (question) {
      if (question.skipped === true) {
        this.$emit('answeredPreviouslySkippedQuestion')
      }
    },
    exitMeasure () {
      const loader = this.$loading.show()
      const params = {
        clientMeasureId: this.singleMeasure.clientMeasureId,
        pageId: this.singleMeasure.page.id,
        complete: false,
        questionAnswers: this.gatherQuestionsAnswers(),
        last_answered_question: this.lastUnansweredId
      }

      this.$store.dispatch(this.savemeasurePageAction, params).then(() => {
        return this.$router.push({ name: 'Session' })
      }).catch((e) => this.$handleApiError(e)
      ).finally(() => loader.hide())
    },
    gatherQuestionsAnswers () {
      const questionsData = {}
      const visibleQuestions = this.getQuestionsToShow()
      for (let i = 0, len = visibleQuestions.length; i < len; i++) {
        const question = visibleQuestions[i]
        const questions = question.isMultiQuestion ? question.multiQuestion : [question]
        for (let j = 0; j < questions.length; j++) {
          const q = questions[j]
          const questionItem = this.singleMeasure.page.questions.find(element => element.id === q.id)
          if (questionItem && question.visible && !questionItem.readOnly) {
            questionsData[q.id] = this.getQuestionValueOrDefault(questionItem)
          }
        }
      }
      return questionsData
    },
    /**
     * Get Selected/Entered Question answer value
     * or send back default value for the question.
     * This case was added to force to send empty string value when checkbox questions has been skipped.
     * */
    getQuestionValueOrDefault (questionItem) {
      return questionItem.value !== null && questionItem.value !== '' ? questionItem.value : ''
    },
    resetState () {
      const s = initialData()
      Object.keys(s).forEach(key => {
        if (key !== 'questionModalShowed') {
          this[key] = s[key]
        }
      })
      scroll(0, this, null, getConfig(this))
    },
    goPrevPage () {
      // Move for non-patient interface scnearios.
      if (this.validateAndDoTherapistPageMove(-1)) {
        return true
      }

      // And what happened for patient logic??
    },
    goBack () {
      this.questionsLoaded = true
      this.fromGoBack = true
      this.goNext()
    },
    getMeasureErrorCallback (error) {
      if (error.status !== 404) {
        this.$handleApiError(error)
      }
      this.$router.push({ name: 'Session' })
    },
    handleSingleMeasureUpdate (newParams, oldParams) {
      // Check if we should redicrect
      if (this.singleMeasure.redirect) {
        return this.redirect()
      }
      if (oldParams && oldParams.pageId && Number(oldParams.pageId) > Number(newParams.pageId) && newParams.measureId === oldParams.measureId) {
        const index = this.getLastAnswerToShow()
        return scrollToStep(index, this, index)
      }
      this.resetState()
      this.goNext(0)
    },
    getMeasureData (newParams, oldParams) {
      return new Promise((resolve, reject) => {
        this.pageId = newParams.pageId
        this.measureId = newParams.measureId
        // If no params reset state
        if (this.measureId === undefined || this.measureId === null || this.pageId === undefined || this.pageId === null) { return this.resetState() }
        const loader = this.$loading.show()
        this.questionsLoaded = false

        this.$store.dispatch(this.getMeasureAction, { id: this.measureId, pageId: this.pageId, measureData: this.auditionData }).then(() => {
          resolve(newParams, oldParams)
        }).catch((e) => {
          reject(e)
        }).finally(() => {
          loader.hide()
          this.questionsLoaded = true
        })
      })
    },
    /**
     * Handle Hiding and reset of the loading element while submitting measure process ends.
     */
    destroySubmitLoadingObject () {
      if (loadingObject) {
        loadingObject.hide()
        loadingObject = null
      }
    },
    /**
     * handle showing and setting of the loading element while submitting a Measure starts.
     */
    createSubmitLoadingObject () {
      if (!loadingObject) {
        loadingObject = this.$loading.show()
      }
    },
    setSkipDoneButtonPressed () {
      this.skipDoneButtonPressed = true
    }
  }
}
</script>

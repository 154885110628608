<template>
  <div class="form-group">
    <textarea
      :id="index"
      v-model="value"
      class="form-control"
      :name="index"
      :readonly="question.readOnly"
      :disabled="question.readOnly"
      @keyup="evt => setSkipButton(evt.target.value)"
      @blur="emitSaveOnly"
      @change="emitChanged(false)"
    />
    <button
      v-if="showDone"
      type="button"
      :disabled="disabledButton"
      class="btn btn-link float-right mt-3 done"
      @click.prevent="emitQuestionAnswered(index, question)"
    >
      {{ skipButton ? $t("skip") : $t("done") }}
    </button>
  </div>
</template>

<script>
import { MeasureTextQuestions } from '@/mixins/MeasureTextQuestions'

export default {
  name: 'QuestionTextarea',
  mixins: [MeasureTextQuestions],
  props: ['question', 'index', 'showDone']
}
</script>
<style lang="scss" scoped>
  textarea {
    height: 100px !important;
  }
</style>

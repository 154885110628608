<template>
  <div>
    <div
      v-for="(option, optionIndex) in question.answers"
      :key="optionIndex"
      class="measure-checkbox-question"
    >
      <input
        :id="`q_${question.id}_${index}_${optionIndex}`"
        ref="checkboxValue"
        type="checkbox"
        :checked="question.value.includes(String(option.value)) && question.value !== ''"
        :name="`checkbox_${index}`"
        :value="option.value"
        :disabled="question.readOnly"
        @change="changedCallback"
      >
      <label
        :for="`q_${question.id}_${index}_${optionIndex}`"
        v-html="option.text"
      />
    </div>
    <button
      v-if="showDone"
      type="button"
      class="btn btn-link float-right mt-3 done"
      @click.prevent="emitQuestionAnswered"
    >
      {{ skipButton ? $t("skip") : $t("done") }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'QuestionCheckbox',
  props: ['question', 'index', 'showDone'], // eslint-disable-line vue/require-prop-types
  data () {
    return {
      skipButton: true,
      hasValue: false,
      answeringTimer: null,
      doneAnsweringTime: 1000
    }
  },
  mounted () {
    this.setSkipButton(this.getQuestionValue())
  },
  methods: {
    changedCallback () {
      this.cancelCurrentSaveRequest()
      clearTimeout(this.answeringTimer)
      this.answeringTimer = setTimeout(() => {
        this.emitChanged()
      }, this.doneAnsweringTime)
    },
    cancelCurrentSaveRequest () {
      this.$store.dispatch('CANCEL_SAVE_QUESTION_REQUEST', { questionId: this.question.id })
    },
    emitSkipButton (values) {
      this.hasValue = !!values.length
      this.$emit('modelChange')
    },
    setSkipButton () {
      const values = this.getQuestionValue()
      this.emitSkipButton(values)
      if (this.question.optional && !values.length) {
        this.skipButton = true
        return
      }
      this.skipButton = false
    },
    getQuestionValue () {
      return this.$refs.checkboxValue.reduce(function (filtered, option) {
        if (option.checked) {
          filtered.push(option.value)
        }
        return filtered
      }, [])
    },
    emitQuestionAnswered () {
      this.$emit('questionAnswered', this.index, this.question, this.getQuestionValue())
    },
    emitChanged () {
      this.$store.dispatch('SET_CHECKBOX_QUESTION_VALUE', { questionId: this.question.id, value: this.getQuestionValue() })
      const value = this.getQuestionValue()
      this.setSkipButton()
      this.$emit('changed', this.index, this.question, value, true)
      this.$emit('saveOnly', value, this.question)
    }
  }
}
</script>

<template>
  <div
    class="outer-flex-container"
    :style="{'min-height': calcHeight(question)}"
    @click="trigerBlurForSafari"
  >
    <!-- we only show the question ( that shows the prompt, parent quesiton) only for no multi question or for the first of all multi questions -->
    <div
      v-if="!question.isMultiQuestion || (question.isMultiQuestion && question.multiQuestion)"
      class="question-container"
    >
      <truncated-text
        v-if="question.prompt && question.hidePrompt !== 1"
        class="prompt"
        :caret="true"
        :default-open="question.prompt.defaultOpen"
        :text="question.prompt.text"
      />
      <p
        v-if="question.parentQuestion && !question.isMultiQuestion"
        class="parent-question-text"
        v-html="question.parentQuestion"
      />
      <!-- dont show the question text if its multiquestion becasue its going to be shown in the  questionMultiText component -->
      <p
        v-if="!question.isMultiQuestion"
        class="question-text"
        :class="{ 'title': question.type === 'title', 'has-parent': question.parentQuestion }"
        v-html="question.text"
      />
    </div>
    <div
      v-if="isValidQuestionType(question)"
      class="options-container"
    >
      <div v-if="question.isMultiQuestion">
        <question-multi-text
          :question="question"
          :index="index"
          @skipDoneButtonPressed="setSkipDoneButtonPressed"
          @saveOnly="saveOnly"
          @goNext="goNext"
          @questionAnswered="emitNext"
        />
      </div>
      <div v-else>
        <question-slider
          v-if="question.type == 'slider'"
          :question="question"
          :index="index"
          :show-done="true"
          @questionAnswered="emitNext"
        />
        <question-radio
          v-if="question.type == 'radio'"
          :question="question"
          :index="index"
          :show-done="true"
          @questionAnswered="emitNext"
        />
        <question-input
          v-if="question.type == 'textinput'"
          :question="question"
          :index="index"
          :show-done="true"
          @questionAnswered="emitNext"
          @saveOnly="saveOnly"
        />
        <question-textarea
          v-if="question.type == 'textarea'"
          :question="question"
          :index="index"
          :show-done="true"
          @questionAnswered="emitNext"
          @saveOnly="saveOnly"
        />
        <question-checkbox
          v-if="question.type == 'checkbox'"
          :question="question"
          :index="index"
          :show-done="true"
          @questionAnswered="emitNext"
          @saveOnly="saveOnly"
        />
        <question-select
          v-if="question.type == 'select'"
          :question="question"
          :index="index"
          :show-done="true"
          @questionAnswered="emitNext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionRadio from '@/components/measures/QuestionRadio'
import QuestionCheckbox from '@/components/measures/QuestionCheckbox'
import QuestionSlider from '@/components/measures/QuestionSlider'
import QuestionSelect from '@/components/measures/QuestionSelect'
import QuestionInput from '@/components/measures/QuestionInput'
import QuestionTextarea from '@/components/measures/QuestionTextarea'
import QuestionMultiText from '@/components/measures/QuestionMultiText'
import TruncatedText from '@/components/common/TruncatedText'

export default {
  name: 'SingleMeasureQuestion',
  components: {
    TruncatedText,
    QuestionRadio,
    QuestionCheckbox,
    QuestionSlider,
    QuestionSelect,
    QuestionInput,
    QuestionMultiText,
    QuestionTextarea
  },
  props: ['questionsLength', 'question', 'index', 'clinicianEditing'],
  data: () => {
    return {
      validTypes: ['textinput', 'slider', 'radio', 'textarea', 'checkbox', 'multiText', 'select', 'tinytext']
    }
  },
  methods: {
    calcHeight (question) {
      if (!question.isMultiQuestion && (question.type === 'text' || question.type === 'title')) {
        return 0
      }

      let minHeight = 0
      const h = window.innerHeight
      const nav = document.getElementById('patient-nav-bar')
      const formHeader = document.getElementById('form-header')
      const clinicianNav = document.getElementById('clinician-nav-bar')
      if (nav) {
        const navH = nav.clientHeight
        const formHeaderH = formHeader ? formHeader.clientHeight : 46
        minHeight = `${h - navH - formHeaderH}px`
      } else if (this.clinicianEditing) {
        minHeight = `${h - 130}px`
      } else if (clinicianNav) {
        const header = document.getElementById('measure-audition-header')
        const navH = clinicianNav.clientHeight
        const headerH = header ? header.clientHeight : 57
        minHeight = `${h - navH - headerH}px`
      }
      return minHeight
    },
    trigerBlurForSafari () {},
    goNext (currentStep) {
      this.$emit('goNext', currentStep)
    },
    emitNext (index, question, value) {
      this.$emit('next', index, question, value)
    },
    saveOnly (value, question) {
      this.$emit('saveOnly', value, question)
    },
    isValidQuestionType (question) {
      return this.validTypes.includes(question.type) || question.isMultiQuestion
    },
    setSkipDoneButtonPressed () {
      this.$emit('skipDoneButtonPressed')
    }
  }
}
</script>

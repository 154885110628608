export const MeasureStepNavigation = {
  methods: {
    // TODO refactor and cleanup this when we have testing so we are confident that we don't break measures edge cases
    getNextUnansweredStep (step, fromGoBack) {
      const entries = Object.values(this.filteredQuestions)
      // When going forward we ignore the optional attribute because all question need to be presented once
      const ignoreOptional = !fromGoBack

      const { missingQuestionPosition, missingTextQuestionPosition } = this.getFirstMissingQuestionAndText(entries, ignoreOptional, step)

      const missingText = missingTextQuestionPosition !== null && missingQuestionPosition !== null
      const missingAfterText = missingText && this.missingAfterText(missingTextQuestionPosition, ignoreOptional)
      const showText = (missingAfterText || missingTextQuestionPosition === (entries.length - 1) || (missingQuestionPosition === null && missingTextQuestionPosition === 0))
      const questionToShow = showText ? missingTextQuestionPosition : missingQuestionPosition

      if (questionToShow === null) {
        this.lastStepPosition = entries.length - 1
        return {
          questionToShow: entries.length - 1,
          missingQuestionPosition: entries.length - 1
        }
      }
      return {
        questionToShow,
        missingQuestionPosition
      }
    },
    missingAfterText (id, ignoreOptional) {
      const found = this.filteredQuestions.find((q, i) => {
        const ignore = this.isInformativeType(q) || (q.optional === true && q.skipped === true)
        if (q.isMultiQuestion) {
          return i > id && this.multiQuestionHasEmptyValues(q, ignoreOptional) && !ignore
        }
        return i > id && !ignore
      })
      return found && (found.isMultiQuestion || found.value === '')
    },
    getValue (question, multi, ignoreOptional) {
      if (question.isMultiQuestion && multi) {
        return this.multiQuestionHasEmptyValues(question, ignoreOptional) ? '' : true
      }

      let actualValue = question.value
      if (question.value instanceof Array) {
        actualValue = ''
        for (let i = 0; i < question.value.length; i++) {
          if (question.value[i]) {
            actualValue = true
            break
          }
        }
      }
      return actualValue
    },
    multiQuestionHasEmptyValues (question, ignoreOptional) {
      let hasEmptyValues = false
      for (let i = 0; i < question.multiQuestion.length; i++) {
        const q = question.multiQuestion[i]
        if (this.isInformativeType(q)) {
          continue
        }

        if (this.getValue(q, false) === '' && (ignoreOptional || !q.optional)) {
          hasEmptyValues = true
          break
        }
      }
      return hasEmptyValues
    },
    getFirstMissingQuestionAndText (questions, ignoreOptional, step) {
      let missingQuestionPosition = null
      let missingTextQuestionPosition = null

      for (let i = !step ? 0 : step; i < questions.length; i++) {
        const question = questions[i]
        const prevIsText = questions[i - 1] && this.isInformativeType(questions[i - 1])
        if ((i !== step && question.isMultiQuestion) && this.multiQuestionHasEmptyValues(question, ignoreOptional)) {
          missingTextQuestionPosition = prevIsText ? missingTextQuestionPosition : i
          missingQuestionPosition = i
          break
        }

        if (i === step && question.isMultiQuestion) {
          continue
        }

        if (question.optional && question.skipped) {
          continue
        }

        // If Question is informative, Search next unanswered question.
        if (!question.isMultiQuestion && this.isInformativeType(question)) {
          missingTextQuestionPosition = prevIsText ? missingTextQuestionPosition : i
          continue
        }

        if (!question.isMultiQuestion && !this.isInformativeType(question) && !prevIsText) {
          missingTextQuestionPosition = null
        }

        const actualValue = this.getValue(question, true, ignoreOptional)
        if (actualValue === '' && i !== missingQuestionPosition && question.keepHiden !== true && (i !== step || step === 0)) {
          missingQuestionPosition = i
          break
        }
      }
      return {
        missingQuestionPosition,
        missingTextQuestionPosition
      }
    }
  }
}

<template>
  <div>
    <div
      v-for="(option, optionIndex) in question.answers"
      :key="optionIndex"
      class="measure-radio-question"
    >
      <input
        :id="`q_${question.id}_${index}_${optionIndex}`"
        type="radio"
        :disabled="disabled"
        :checked="question.value == option.value && question.value !== ''"
        :class="disabled"
        :name="`radio_${index}`"
        :value="option.value"
        @change="emitQuestionAnswered(index, question, option.value)"
      >
      <label
        :for="`q_${question.id}_${index}_${optionIndex}`"
        v-html="option.text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionRadio',
  props: ['question', 'index'],
  data () {
    return {
      hasValue: false
    }
  },
  computed: {
    disabled () {
      return !!this.question.readOnly
    }
  },
  mounted () {
    this.emitSkipButton(this.question.value)
  },
  methods: {
    emitSkipButton (value) {
      this.hasValue = value !== ''
      this.$emit('modelChange')
    },
    emitQuestionAnswered (index, question, value) {
      this.emitSkipButton(value)
      this.$emit('questionAnswered', index, question, value)
      this.$emit('changed', index, question, value, true)
    }
  }
}
</script>

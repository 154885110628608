<template>
  <div
    :id="`item-${questionsLength}`"
    class="single-measure-finish"
    :class="{'only-one-informative': !showUnansweredQuestionCount, 'clinician-finish': customMeasureId, 'is-catmh': isCatMh}"
  >
    <div v-if="(customMeasureId || isCatMh) && !audition">
      <button
        type="button"
        :disabled="isCatMh && !!totalUnanswered"
        class="btn btn-primary text-capitalize"
        @click.prevent="submit"
      >
        {{ buttonText }}
      </button>
    </div>
    <div v-else>
      <div v-if="showUnansweredQuestionCount">
        <div id="responsive-outer-circle">
          <div id="responsive-inner-circle">
            <span v-if="totalUnanswered"> {{ totalUnanswered }} </span>
            <i
              v-else
              class="fa fa-check"
            />
          </div>
        </div>
      </div>
      <div
        v-if="showUnansweredQuestionCount"
        class="texts"
      >
        <h2>{{ mainText }}</h2>
        <p v-if="!hasMorePages && !audition">
          {{ secondaryText }}
        </p>
      </div>
      <div
        v-else
        class="texts"
      >
        <h2>{{ $t('sectionCompleted') }}</h2>
      </div>
      <div
        v-if="totalUnanswered"
        class="button"
      >
        <button
          type="button"
          class="btn btn-primary text-capitalize"
          @click.prevent="goBack"
        >
          {{ $t("goBack") }}
        </button>
        <button
          type="button"
          class="btn btn-link"
          @click.prevent="submit"
        >
          {{ continueText }}
        </button>
      </div>
      <div
        v-else
        class="button"
      >
        <button
          type="button"
          class="btn btn-primary text-capitalize"
          @click.prevent="submit"
        >
          {{ submitText }}
        </button>
        <div
          v-if="!hasMorePages && !clinicianEditing"
          class="texts"
        >
          <p class="note">
            {{ $t("finishMeasureNote") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleMeasureFinish',
  props: ['totalUnanswered', 'hasMorePages', 'questionsLength', 'showUnansweredQuestionCount', 'customMeasureId', 'isCatMh', 'pageNumber', 'clinicianEditing', 'audition', 'isScorable'],
  computed: {
    mainText () {
      const totalUnanswered = this.totalUnanswered
      const pluralSelector = totalUnanswered === 1 ? 2 : 1
      return totalUnanswered ? this.$tc('notAllQuestionsAnswered', pluralSelector, { number: totalUnanswered }) : this.$t('allQuestionsAnswered')
    },
    clinicianSaveMessage () {
      return this.isScorable ? this.$t('saveToScore') : this.$t('saveToComplete')
    },
    secondaryText () {
      return this.clinicianEditing ? this.clinicianSaveMessage : this.$t('clickToSendToClinician')
    },
    continueText () {
      const index = this.clinicianEditing ? 'saveChangesAnyway' : this.hasMorePages ? 'continueAnyWay' : this.audition ? 'viewResults' : 'submitAnyWay'
      return this.$t(index)
    },
    submitText () {
      const index = this.hasMorePages ? 'next' : this.clinicianEditing ? 'saveChanges' : this.audition ? 'viewResults' : 'submit'
      return this.$t(index)
    },
    buttonText () {
      let translateIndex = 'save'
      if (this.isCatMh) {
        translateIndex = this.pageNumber === 1 ? 'begin' : this.hasMorePages ? 'next' : 'continue'
      }
      return this.$t(translateIndex)
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
    },
    submit () {
      // don't submit if is isCatMh and has unanswered questions
      if (!(this.isCatMh && this.totalUnanswered)) {
        this.$emit('submit', false, !this.hasMorePages, false)
      }
    }
  }
}
</script>

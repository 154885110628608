<template>
  <div class="multi-text-question">
    <div class="form-group">
      <input
        :id="index"
        v-model="value"
        autocomplete="off"
        type="text"
        class="form-control tiny-text-input"
        :name="index"
        :readonly="question.readOnly"
        :disabled="question.readOnly"
        @keydown.enter.prevent="emitQuestionAnswered(index, question)"
        @keyup="evt => setSkipButton(evt.target.value)"
        @change="emitChanged(true)"
      >
      <div
        class="display-flex-aligned"
        v-html="question.text"
      />
      <button
        v-if="showDone"
        type="button"
        :disabled="disabledButton"
        class="btn btn-link float-right mt-3 done"
        @click.prevent="emitQuestionAnswered(index, question)"
      >
        {{ skipButton ? $t("skip") : $t("done") }}
      </button>
    </div>
  </div>
</template>

<script>
import { MeasureTextQuestions } from '@/mixins/MeasureTextQuestions'

export default {
  name: 'QuestionInput',
  mixins: [MeasureTextQuestions],
  props: ['question', 'index', 'showDone']
}
</script>

<template>
  <div class="measure-radio-slider">
    <div
      v-for="(answer, i) in question.answers"
      :key="i"
      class="slider-options"
    >
      <button
        type="text"
        class="slider-option"
        :disabled="question.readOnly"
        :class="{active:(answer.value == question.value && question.value != '') }"
        @click.prevent="emitQuestionAnswered(answer.value)"
      >
        {{ answer.text.replace(/[^0-9.]/g, "").trim() }}
      </button>
      <span
        class="slider-option-text"
        v-html="answer.text.replace(/[0-9]/g, '').trim()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionSlider',
  props: ['question', 'index'], // eslint-disable-line vue/require-prop-types
  data () {
    return {
      hasValue: false
    }
  },
  mounted () {
    this.emitSkipButton(this.question.value)
  },
  methods: {
    emitSkipButton (value) {
      this.hasValue = !!value
      this.$emit('modelChange')
    },
    emitQuestionAnswered (value) {
      this.emitSkipButton(value)
      this.$emit('changed', this.index, this.question, value, true)
      this.$emit('questionAnswered', this.index, this.question, value)
    }
  }
}
</script>

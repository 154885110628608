<template>
  <div>
    <div
      v-for="(q, i) in question.multiQuestion"
      :key="question.id+'-'+q.id"
    >
      <div class="clearfix" />
      <div class="question-container no-padding">
        <p
          v-if="q.parentQuestion"
          class="parent-question-text"
          v-html="q.parentQuestion"
        />
        <p
          v-if="q.type != 'tinytext' "
          class="question-text"
          :class="{ 'title': q.type === 'title', 'has-parent': q.parentQuestion }"
          v-html="q.text"
        />
      </div>
      <question-slider
        v-if="q.type == 'slider'"
        ref="childQuestion"
        :question="q"
        :index="question.id+'-'+i"
        :show-done="false"
        @changed="questionValueChanged"
        @modelChange="setSkipButton"
      />
      <question-radio
        v-if="q.type == 'radio'"
        ref="childQuestion"
        :question="q"
        :index="question.id+'-'+i"
        :show-done="false"
        @changed="questionValueChanged"
        @modelChange="setSkipButton"
      />
      <question-input
        v-if="q.type == 'textinput'"
        ref="childQuestion"
        :question="q"
        :index="question.id+'-'+i"
        :show-done="false"
        @changed="questionValueChanged"
        @saveOnly="(v, q) => $emit('saveOnly', v, q)"
        @modelChange="setSkipButton"
      />
      <question-textarea
        v-if="q.type == 'textarea'"
        ref="childQuestion"
        :question="q"
        :index="question.id+'-'+i"
        :show-done="false"
        @changed="questionValueChanged"
        @saveOnly="(v, q) => $emit('saveOnly', v, q)"
        @modelChange="setSkipButton"
      />
      <question-checkbox
        v-if="q.type == 'checkbox'"
        ref="childQuestion"
        :question="q"
        :index="question.id+'-'+i"
        :show-done="false"
        @changed="questionCheckboxChanged"
        @saveOnly="(v, q) => $emit('saveOnly', v, q)"
        @modelChange="setSkipButton"
      />
      <question-select
        v-if="q.type == 'select'"
        ref="childQuestion"
        :question="q"
        :index="question.id+'-'+i"
        :show-done="false"
        @changed="questionValueChanged"
        @modelChange="setSkipButton"
      />
      <question-input-tiny-text
        v-if="q.type == 'tinytext'"
        ref="childQuestion"
        :question="q"
        :index="question.id+'-'+i"
        :show-done="false"
        @changed="questionValueChanged"
        @modelChange="setSkipButton"
      />
    </div>
    <button
      type="button"
      class="btn btn-link float-right mt-3 done"
      @click.prevent="saveQuestions()"
    >
      {{ skipButton ? $t("skip") : $t("done") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QuestionRadio from '@/components/measures/QuestionRadio'
import QuestionCheckbox from '@/components/measures/QuestionCheckbox'
import QuestionSlider from '@/components/measures/QuestionSlider'
import QuestionSelect from '@/components/measures/QuestionSelect'
import QuestionInput from '@/components/measures/QuestionInput'
import QuestionTextarea from '@/components/measures/QuestionTextarea'
import QuestionInputTinyText from '@/components/measures/QuestionInputTinyText'

export default {
  name: 'QuestionMultitext',
  components: {
    QuestionRadio,
    QuestionCheckbox,
    QuestionSlider,
    QuestionSelect,
    QuestionInput,
    QuestionTextarea,
    QuestionInputTinyText
  },
  props: ['question', 'index'],
  data () {
    return {
      values: [],
      skipButton: true
    }
  },
  computed: {
    ...mapGetters({
      settings: 'generalSettings'
    })
  },
  mounted () {
    this.setSkipButton()
  },
  methods: {
    getValues () {
      return this.values
    },
    questionCheckboxChanged (index, question, value, autoSave) {
      const elementIndex = this.values.findIndex((a) => a.id === question.id)
      if (elementIndex === -1) {
        this.values.push({ value: value, id: question.id })
        return true
      }
      this.values[elementIndex].value = value
    },
    questionValueChanged (index, question, value, autoSave) {
      const elementIndex = this.values.findIndex((a) => a.id === question.id)
      if (elementIndex === -1) {
        this.values.push({ value: value, id: question.id, readOnly: question.readOnly })
        this.emitSaveOnly(value, question, autoSave)
        return true
      }
      this.values[elementIndex].value = value
      this.emitSaveOnly(value, question, autoSave)
    },
    emitSaveOnly (value, question, autoSave) {
      if (autoSave) {
        this.$emit('saveOnly', value, question)
      }
    },
    saveQuestions () {
      const values = this.getValues()
      this.$emit('skipDoneButtonPressed')
      if (this.values.length) {
        return this.$emit('questionAnswered', this.index, this.question, values)
      }
      return this.$emit('goNext', this.index)
    },
    setSkipButton () {
      let showSkip = true
      if (this.$refs.childQuestion) {
        for (let i = 0; i < this.$refs.childQuestion.length; i++) {
          const element = this.$refs.childQuestion[i]
          if (element.hasValue) {
            showSkip = false
            break
          }
        }
      }
      this.skipButton = showSkip
    }
  }
}
</script>
